// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/truthist-primary-icon-button.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/truthist-primary-icon-button.tsx");
  import.meta.hot.lastModified = "1733459677540.6008";
}
// REMIX HMR END

import { IconButton, useColorModeValue } from "@chakra-ui/react";
export default function TruthistPrimaryIconButton({
  children,
  label,
  ...rest
}) {
  _s();
  return <IconButton size={"sm"} justifyItems={"center"} border={"1px"} borderColor={useColorModeValue("gray.400", "gray.600")} borderRadius={"md"} aria-label={label} icon={children} type="submit" _hover={{
    backgroundColor: useColorModeValue("gray.400", "gray.600")
  }} {...rest} />;
}
_s(TruthistPrimaryIconButton, "5rxRexUsrrZIvnjV+vC1pFVSBPE=", false, function () {
  return [useColorModeValue, useColorModeValue];
});
_c = TruthistPrimaryIconButton;
var _c;
$RefreshReg$(_c, "TruthistPrimaryIconButton");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;